import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import createHttp from '@/utils/axiosHttp';
import { isResponse } from '@/types';
import NProgress from 'nprogress';
import Cookie from 'js-cookie';
import { messageError } from '@/utils/antdMess'
import { Modal } from 'ant-design-vue';

const http = createHttp({
  timeout: 60000,
  baseURL: import.meta.env.VITE_API_URL,
  // withCredentials: true,
  // xsrfCookieName: 'Authorization',
  // xsrfHeaderName: 'Authorization',
});

let show = false
const isAxiosResponse = (obj: any): obj is AxiosResponse => {
  return typeof obj === 'object' && obj.status && obj.statusText && obj.headers && obj.config;
};

// progress 进度条 -- 开启
http.interceptors.request.use((req: AxiosRequestConfig) => {

  if (Cookie.get('token')) {
    //用户登录完成之后 ，每次请求都携带请求头的token值，
    req.headers.Authorization = Cookie.get('token')
  }
  if (!NProgress.isStarted()) {
    NProgress.start();
  }
  return req;
});

// 解析响应结果
http.interceptors.response.use(
  (rep: AxiosResponse<String>) => {
    const { data }: any = rep;
    if (data.code == 0 && data.message == '请登录' && rep.config.url != "/menu/mymenu") {
      if (!show) {
        show = true
        Modal.confirm({
          title: `系统提示 `,
          icon: "",
          content: "登录状态已过期，您可以继续留在该页面，或者重新登录",
          centered: true,
          okText: '确认',
          cancelText: '取消',
          onOk() {
            show = false
            localStorage.clear()
            http.removeAuthorization();
            location.href = '#/login';
          },
          onCancel() {
            show = false
          },
          class: 'test',
        });
      }
      return
    }
    if (isResponse(data)) {
      return data.code === 0 ? data : Promise.reject(data);
    }

    return Promise.reject({ message: rep.statusText, code: rep.status, data });
  },
  (error) => {
    if (error.response && isAxiosResponse(error.response)) {
      console.log(error.response);
      // 等于401，返回login
      if (error.response.status == "0" && error.config.url != "/authorizations") {
        messageError("登录失效，请重新登录")
        return location.href = '#/login';
      }
      return Promise.reject({
        message: error.response.statusText,
        code: error.response.status,
        data: error.response.data,
      });
    }
    messageError("请求错误，请联系管理员")
    return Promise.reject(error);
  }
);

// progress 进度条 -- 关闭
http.interceptors.response.use(
  (rep) => {
    if (NProgress.isStarted()) {
      NProgress.done();
    }
    return rep;
  },
  (error) => {
    if (NProgress.isStarted()) {
      NProgress.done();
    }
    return error;
  }
);

export default http;
